import React from "react";
import classes from "./DivisionsWrapper.module.scss";
import DivisionPreview from "../DivisionPreview/DivisionPreview";
import Parser from "html-react-parser";

export default function DivisionsWrapper(props) {
  // let about = (
  //   <div className={classes.Padding}>
  //     <h1 className={classes.Header}>{props.header.title}</h1>
  //     {props.header.text != null && Parser(props.header.text)}
  //   </div>
  // );

  return (
    <div>
      {props.divisions
        .sort(function (a, b) {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        })
        .sort((a, b) => a.weight - b.weight)
        .map((division) => (
          <DivisionPreview division={division} key={division.id} />
        ))}
    </div>
  );
}
