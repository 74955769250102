import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import * as actions from "../../store/actions/index";
// import MissionStatementSidebar from './MissionStatementSidebar/MissionStatementSidebar';
import classes from "./MissionStatementSidebar.module.scss";

class MissionStatement extends Component {
  componentDidMount() {
    this.props.onFetchMissionStatement();
  }

  render() {
    let classConditional =
      this.props.type == "sidebar"
        ? classes.SidebarWrapper
        : classes.BannerWrapper;

    let missionStatement = null;

    if (!this.props.loading) {
      missionStatement = (
        <>
          <div className={classConditional}>
            <div className={classes.Card}>
              <h3 className={classes.Header}>
                {this.props.missionStatement.header}
              </h3>
              <p className={classes.Tagline}>
                {this.props.missionStatement.tagline}
              </p>
              <ul className={classes.List}>
                {this.props.missionStatement.points.map((element) => (
                  <li className={classes.ListItem} key={element.key}>
                    {element.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      );
    }

    return <div>{missionStatement}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    missionStatement: state.missionStatement.missionStatement,
    loading: state.missionStatement.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchMissionStatement: () => dispatch(actions.fetchMissionStatement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionStatement);
