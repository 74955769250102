import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Layout.module.scss";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import Footer from "../../components/Footer/Footer";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import AlertAtTheTopOfThePage from "../AlertAtTheTopOfThePage/AlertAtTheTopOfThePage";

class Layout extends Component {
  state = {
    showSideDrawer: false,
    showMore: false,
  };

  removeOnResize = () => {
    if (window.innerWidth >= 1200 && this.state.showSideDrawer) {
      this.setState({
        showSideDrawer: false,
        showMore: false,
      });
    }
  };

  componentDidMount() {
    this.removeOnResize();
    window.addEventListener("resize", this.removeOnResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.removeOnResize.bind(this));
  }

  sideDrawerClosedHandler = () => {
    this.setState({
      showSideDrawer: false,
      showMore: false,
    });
  };

  sideDrawerToggleHandler = () => {
    this.setState((prevState) => {
      return {
        showSideDrawer: !prevState.showSideDrawer,
        showMore: false,
      };
    });
  };

  showMoreToggleHandler = () => {
    this.setState((prevState) => {
      return { showMore: !prevState.showMore };
    });
  };

  render() {
    return (
      <div className={classes.Container}>
        <Toolbar
          drawerToggleClicked={this.sideDrawerToggleHandler}
          closed={this.sideDrawerClosedHandler}
          open={this.state.showSideDrawer}
        />

        <SideDrawer
          open={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
          moreOpen={this.state.showMore}
          moreToggle={this.showMoreToggleHandler}
        />
        {/*<AlertAtTheTopOfThePage isOn={false} />*/}
        <main
          onClick={this.sideDrawerClosedHandler}
          className={classes.Content}
        >
          {this.props.children}
        </main>

        <Footer closed={this.sideDrawerClosedHandler} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(Layout);
