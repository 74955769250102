import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Panel from "./Panel/Panel";

export default function Jumbotron(props) {
  let multipleSlides = props.jumbotronData.length == 1 ? false : true;

  return (
    <Carousel
      showIndicators={multipleSlides}
      swipeScrollTolerance={12}
      showThumbs={props.showThumbs}
      showStatus={props.showStatus}
      infiniteLoop={multipleSlides}
      useKeyboardArrows={props.useKeyboardArrows}
      autoPlay={props.autoPlay}
      stopOnHover={props.stopOnHover}
      swipeable={multipleSlides}
      interval={props.interval}
      transitionTime={props.transitionTime}
    >
      {props.jumbotronData.map((current) => (
        <Panel key={current.nid} data={current} button={props.button} />
      ))}
    </Carousel>
  );
}
