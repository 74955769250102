import * as actionTypes from "./actionTypes";
// import axios from 'axios'
import axios from "../../axios-api";

export const fetchHomeStart = () => {
  return {
    type: actionTypes.HOME,
  };
};

export const fetchHomeSuccess = (home) => {
  return {
    type: actionTypes.FETCH_HOME_SUCCESS,
    home: home,
  };
};

export const fetchHomeFail = (error) => {
  return {
    type: actionTypes.FETCH_HOME_FAIL,
    error: error,
  };
};

export const fetchHome = () => {
  return (dispatch) => {
    dispatch(fetchHomeStart());
    axios
      .get("/mfg/v1/home")
      .then((res) => {
        dispatch(fetchHomeSuccess(res.data));
      })
      .catch((err) => {
        // console.log(err)
        // console.log('err')
        dispatch(fetchHomeFail(err));
      });
  };
};
