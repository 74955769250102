import React from "react";
import classes from "./sidebar.module.scss";
import MissionStatement from "./../../components/MissionStatement/MissionStatement";
import SidebarSquares from "./../../components/SidebarSquares/SidebarSquares";

export default function Sidebar(props) {
  return (
    <div className={classes.Wrapper}>
      <MissionStatement type={"sidebar"} />
      <SidebarSquares squares={props.squares} />
    </div>
  );
}
