import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import Spinner from "../../components/UI/Spinner/Spinner";
import classes from "./Facilities.module.scss";
import Jumbotron from "./../../components/Jumbotron/Jumbotron";
import FacilitiesWrapper from "./FacilitiesWrapper/FacilitiesWrapper";
import Sidebar from "./../Sidebar/Sidebar";
import ErrorDisplay from "./../../components/ErrorDisplay/ErrorDisplay";
class Facilities extends Component {
  componentDidMount() {
    this.props.onFetchFacilities();
    document.title = "Facilities | Great Plains Manufacturing";
  }

  render() {
    // console.log(this.props)
    //will render a loading icon if the data isn't loaded in yet
    let content = <Spinner />;
    let jumbotron = null;
    let facilities = null;
    let sidebar = null;

    // console.log("before chek", this.props)
    if (!this.props.loading) {
      if (!this.props.error) {
        // goes through each bucket and adds the banner
        jumbotron = (
          <Jumbotron
            jumbotronData={this.props.jumbotronData}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            autoPlay={true}
            stopOnHover={true}
            interval={10000}
            transitionTime={350}
          />
        );

        sidebar = (
          <div className={classes.Sidebar}>
            <Sidebar squares={this.props.sidebarSquares} />
          </div>
        );

        facilities = (
          <div className={classes.Facilities}>
            <FacilitiesWrapper
              header={this.props.header}
              facilities={this.props.facilities}
            />
          </div>
        );

        content = (
          <Fragment>
            {jumbotron}
            <div className={classes.Wrapper}>
              {facilities}
              {sidebar}
            </div>
          </Fragment>
        );
      } else {
        content = <ErrorDisplay />;
      }
    }

    //todo add this
    // if(this.props.error){
    //   facilities = <div >There was an error, catch this</div>
    // }

    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    facilities: state.facilities.facilities,
    jumbotronData: state.facilities.jumbotronData,
    loading: state.facilities.loading,
    error: state.facilities.error,
    header: state.facilities.header,
    sidebarSquares: state.facilities.sidebarSquares,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchFacilities: () => dispatch(actions.fetchFacilities()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Facilities);
