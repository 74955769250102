import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./NavigationItems.module.scss";
import NavigationItem from "./NavigationItem/NavigationItem";

class navigationItems extends Component {
  render() {
    //will render a loading icon if the data isn't loaded in yet
    let navItems = <div>loading</div>;
    if (!this.props.loading) {
      navItems = this.props.menuItems.map((item, index) =>
        item.type === "link" ? (
          <NavigationItem key={index} link={item.link}>
            {item.text}
          </NavigationItem>
        ) : (
          <NavigationItem key={index} href={item.link}>
            {item.text}
          </NavigationItem>
        )
      );
    }
    return (
      <div>
        <ul className={classes.NavigationItems}>{navItems}</ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuItems: state.header.header.primary,
    loading: state.header.loading,
  };
};

export default connect(mapStateToProps)(navigationItems);
