import React from "react";
import { Link } from "react-router-dom";

export default function LearnMoreButton(props) {
  return (
    <div>
      {props.buttonLink[0] != undefined ||
      props.buttonLink[0].length() > 0 ||
      props.buttonLink[0] == "/" ? (
        <Link to={props.buttonLink}>LEARN MORE</Link>
      ) : (
        <a target="_blank" href={props.buttonLink}>
          LEARN MORE
        </a>
      )}
    </div>
  );
}
