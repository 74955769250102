import React from "react";
import classes from "./SidebarSquare.module.scss";
import { Link } from "react-router-dom";

export default function SidebarSquare(props) {
  let imageUrl = props.square.field_background_image;
  let classList = [classes.Wrapper];

  //setting background style information here because I need to pass variable to it
  let style = {
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  // adds classes to the square based on their position in the array

  let classString = "";
  classList.forEach((element) => {
    classString += element + " ";
  });

  return (
    <div className={classString} style={style}>
      <div className={classes.Content}>
        <div>
          <img className={classes.Icon} src={props.square.field_icon} />
        </div>
        <div className={classes.Title}>{props.square.square_title}</div>
        <div className={classes.Subtitle}>{props.square.field_caption}</div>
        {props.square.field_link_square[0] == "/" ? (
          <Link className={classes.Button} to={props.square.field_link_square}>
            LEARN MORE
          </Link>
        ) : (
          <a
            className={classes.Button}
            target="_blank"
            href={props.square.field_link_square}
          >
            LEARN MORE
          </a>
        )}
      </div>
    </div>
  );
}

/*import React, { Fragment } from 'react'

export default function SidebarSquare(props) {
  console.log("sidebarSquares", props.squares);
  return (
    <Fragment>
      {props.squares.map(element => (
        <div>
          <h3>{element.title}</h3>

        </div>
      ))}
    </Fragment>
  )
}
*/
