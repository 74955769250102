import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "axios";
import Spinner from "../../components/UI/Spinner/Spinner";
import classes from "./Divisions.module.scss";
import Jumbotron from "./../../components/Jumbotron/Jumbotron";
import DivisionsWrapper from "./DivisionsWrapper/DivisionsWrapper";
import Sidebar from "./../Sidebar/Sidebar";
import ErrorDisplay from "./../../components/ErrorDisplay/ErrorDisplay";
class Divisions extends Component {
  componentDidMount() {
    this.props.onFetchDivisions();
    document.title = "Divisions | Great Plains Manufacturing";
  }

  render() {
    //will render a loading icon if the data isn't loaded in yet
    let content = <Spinner />;
    let jumbotron = null;
    if (!this.props.loading) {
      if (!this.props.error) {
        // goes through each bucket and adds the banner
        jumbotron = (
          <Jumbotron
            jumbotronData={this.props.jumbotronData}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            autoPlay={true}
            stopOnHover={true}
            interval={10000}
            transitionTime={350}
          />
        );
        let divisions = (
          <div className={classes.Divisions}>
            <DivisionsWrapper
              // header={this.props.header}
              divisions={this.props.divisions}
            />
          </div>
        );

        let sidebar = (
          <div className={classes.Sidebar}>
            <Sidebar squares={this.props.sidebarSquares} />
          </div>
        );

        content = (
          <Fragment>
            {jumbotron}
            <div className={classes.Wrapper}>
              {divisions}
              {sidebar}
            </div>
          </Fragment>
        );
      } else {
        content = <ErrorDisplay />;
      }
    }

    //todo add this
    // if(this.props.error){
    //   divisions = <div >There was an error, catch this</div>
    // }

    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.divisions.loading,
    error: state.divisions.error,
    divisions: state.divisions.divisions,
    jumbotronData: state.divisions.jumbotronData,
    header: state.divisions.header,
    sidebarSquares: state.divisions.sidebarSquares,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDivisions: () => dispatch(actions.fetchDivisions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Divisions, axios));
