import React from "react";
import classes from "../PrivacyPolicy/PrivacyPolicy.module.scss";

export default function Ukraine() {
  return (
    <div className={classes.Wrapper}>
      <h2>Statement on Ukraine</h2>

      <p>
        Kubota stands with those who stand for peace. We are concerned for the
        well-being of the people in Ukraine, and we continue to keep them in our
        thoughts. Kubota North America, and its subsidiary Great Plains
        Manufacturing, cancelled production of equipment orders from Russia, and
        we are fully complying with domestic and international sanctions. We
        will continue to monitor events closely.
      </p>

      <p>
        Since its founding in 1890, Kubota has been working to achieve solutions
        on social issues related to food, water, and the environment. We remain
        committed to confronting these increasingly serious social issues during
        this humanitarian crisis in Ukraine. Kubota companies in Japan, Europe
        and North America, including Great Plains Manufacturing, are donating
        100 million Japanese yen (about 850,000 U.S. dollars) to support
        Ukrainian families through relief organizations providing aid, shelter,
        food, water, blankets, medical supplies and protection from harm to
        those in need. Kubota employees have taken several initiatives to assist
        Ukraine, and we can only wish for a fast resolution to avoid further
        suffering.
      </p>
    </div>
  );
}
