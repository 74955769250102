import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavigationItem.module.scss";

const navigationItem = (props) => (
  <li className={classes.NavigationItem}>
    {props.link ? (
      <NavLink
        to={props.link}
        exact={props.exact}
        activeClassName={classes.active}
      >
        {props.children}
      </NavLink>
    ) : (
      <a href={props.href} target="_blank">
        {props.children}
      </a>
    )}
  </li>
);

export default navigationItem;
