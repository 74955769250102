import React from "react";
import Square from "./Square/Square";
import classes from "./Squares.module.scss";

export default function Squares(props) {
  let fullCheck = [];
  let leftRight = [];

  props.squares
    .sort((a, b) => a.weight - b.weight)
    .map((current, index) => {
      if (index == 0 || (index == props.squares.length - 1 && index % 2 != 0)) {
        fullCheck[index] = true;
      } else {
        if (index % 2 != 0) {
          leftRight[index] = "left";
        } else {
          leftRight[index] = "right";
        }
      }
    });

  return (
    <div className={classes.Wrapper}>
      {props.squares.map((current, index) => (
        <Square
          key={current.id}
          index={index}
          square={current}
          side={leftRight[index]}
          full={fullCheck[index]}
        />
      ))}
    </div>
  );
}
