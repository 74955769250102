import * as actionTypes from "./actionTypes";
// import axios from 'axios'
import axios from "../../axios-api";

export const fetchDivisionsStart = () => {
  return {
    type: actionTypes.DIVISIONS,
  };
};

export const fetchDivisionsSuccess = (divisions) => {
  return {
    type: actionTypes.FETCH_DIVISIONS_SUCCESS,
    divisions: divisions,
  };
};

export const fetchDivisionsFail = (error) => {
  return {
    type: actionTypes.FETCH_DIVISIONS_FAIL,
    error: error,
  };
};

export const fetchDivisions = () => {
  return (dispatch) => {
    dispatch(fetchDivisionsStart());
    axios
      .get("/mfg/v1/divisions/")
      .then((res) => {
        console.log("response");
        console.log(res);
        dispatch(fetchDivisionsSuccess(res.data));
      })
      .catch((err) => {
        // console.log(err)
        // console.log('err')
        dispatch(fetchDivisionsFail(err));
      });
  };
};
