import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";
import React, { Component } from "react";
import classes from "./Toolbar.module.scss";
import Primary from "./Primary/Primary.js";
import Secondary from "./Secondary/Secondary.js";
import Tertiary from "./Tertiary/Tertiary.js";
// import NavigationItems from './../NavigationItems/NavigationItems'
// export default toolbar;

class Header extends Component {
  componentDidMount() {
    this.props.onFetchHeader();
  }

  render() {
    let toolbar = null;

    if (!this.props.loading) {
      toolbar = (
        <div>
          <header className={classes.Toolbar}>
            <Tertiary></Tertiary>
            <Primary
              drawerToggleClicked={this.props.drawerToggleClicked}
              closed={this.props.closed}
              open={this.props.open}
            ></Primary>
            <Secondary></Secondary>
          </header>
        </div>
      );
    }
    return <div>{toolbar}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    header: state.header.header,
    loading: state.header.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchHeader: () => dispatch(actions.fetchHeader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
