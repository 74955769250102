import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./NavigationItems.module.scss";
import NavigationItem from "./NavigationItem/NavigationItem";
import { Link } from "react-router-dom";
import Icon from "../../../../../assets/images/file-lines-solid.svg";
class navigationItems extends Component {
  render() {
    //will render a loading icon if the data isn't loaded in yet
    let navItems = <div>loading</div>;
    if (!this.props.loading) {
      navItems = this.props.menuItems.map((item, index) =>
        item.type === "link" ? (
          <NavigationItem key={index} link={item.link} icon={item.icon} exact>
            {item.text}
          </NavigationItem>
        ) : (
          <NavigationItem key={index} href={item.link} icon={item.icon} exact>
            {item.text}
          </NavigationItem>
        )
      );
    }
    return (
      <div>
        <ul className={classes.NavigationItems}>
          {navItems}
          <img className={classes.ICON} src={Icon} />
          <Link to={"/statement-on-ukraine"} className={classes.Incident}>
            Statement on Ukraine
          </Link>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuItems: state.header.header.tertiary,
    loading: state.header.loading,
  };
};

export default connect(mapStateToProps)(navigationItems);

// import React, {Fragment} from 'react';
// import classes from './NavigationItems.module.scss';
// import NavigationItem from './NavigationItem/NavigationItem';

// const navigationItems = (props) => (
//     <ul className={classes.NavigationItems}>
//         <NavigationItem icon="home" link="/" exact>             HOME</NavigationItem>
//         <NavigationItem icon="lock" href="https://dealers.landpride.com">        LP DEALER ACCESS</NavigationItem>
//         <NavigationItem icon="lock" href="https://dealers.greatplainsmfg.com" >GP DEALER ACCESS</NavigationItem>
//         <NavigationItem icon="current" link="/corporate-video" exact>  COMPANY WEBSITES</NavigationItem>
//     </ul>
// );

// export default navigationItems;
