import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "axios";
import Spinner from "../../components/UI/Spinner/Spinner";
import Squares from "../../components/Squares/Squares";
import Jumbotron from "./../../components/Jumbotron/Jumbotron";
import MissonStatement from "./../../components/MissionStatement/MissionStatement";
import HomeBanner from "./HomeBanner/HomeBanner";
import ErrorDisplay from "./../../components/ErrorDisplay/ErrorDisplay";

class Home extends Component {
  componentDidMount() {
    this.props.onFetchHome();
    this.props.onFetchMissionStatement();
    document.title = "Home | Great Plains Manufacturing";
  }

  render() {
    //will render a loading icon if the data isn't loaded in yet
    let content = <Spinner />;
    let jumbotron = null;
    let squares = null;
    let missionStatement = null;
    let homeBanner = null;

    if (!this.props.loading) {
      if (!this.props.error) {
        squares = <Squares squares={this.props.home.squares} />;
        jumbotron = (
          <Jumbotron
            jumbotronData={this.props.jumbotronData}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            autoPlay={true}
            stopOnHover={true}
            interval={10000}
            transitionTime={350}
          />
        );
        missionStatement = <MissonStatement type={"banner"} />;
        homeBanner = <HomeBanner />;

        content = (
          <div>
            {jumbotron}
            {homeBanner}
            {squares}
            {missionStatement}
          </div>
        );
      } else {
        content = <ErrorDisplay />;
      }
    }

    //todo add this
    // if(this.props.error){
    //   facilities = <div >There was an error, catch this</div>
    // }

    return <Fragment>{content}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.home.loading,
    error: state.home.error,
    jumbotronData: state.home.home.banners,
    home: state.home.home,
    missionStatement: state.missionStatement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchHome: () => dispatch(actions.fetchHome()),
    onFetchMissionStatement: () => dispatch(actions.fetchMissionStatement()),
  };
};

// export default Home;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Home, axios));
