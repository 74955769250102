import * as actionTypes from "./actionTypes";
// import axios from 'axios'
import YAML from "js-yaml";
import YamlPath from "./../../components/MissionStatement/settings.yml";

export const fetchMissionStatementStart = () => {
  return {
    type: actionTypes.MISSIONSTATEMENT,
  };
};

export const fetchMissionStatementSuccess = (missionStatement) => {
  return {
    type: actionTypes.FETCH_MISSIONSTATEMENT_SUCCESS,
    missionStatement: missionStatement,
  };
};

export const fetchMissionStatementFail = (error) => {
  return {
    type: actionTypes.FETCH_MISSIONSTATEMENT_FAIL,
    error: error,
  };
};

export const fetchMissionStatement = () => {
  return (dispatch) => {
    dispatch(fetchMissionStatementStart());

    fetch(YamlPath)
      .then((r) => r.text())
      .then((text) => {
        const doc = YAML.load(text);
        // console.log(doc)
        dispatch(fetchMissionStatementSuccess(doc));
      })
      .catch((err) => {
        console.log("the missionStatement fetch messed up");
        dispatch(fetchMissionStatementFail(err));
      });
  };
};
