import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  clearApiKey: "",
  path: "",
  loading: true,
};

const fetchAdminStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchAdminSuccess = (state, action) => {
  return updateObject(state, {
    clearApiKey: action.config.clearApiKey,
    path: action.config.path,
    loading: false,
  });
};

const fetchAdminFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const adminLogout = (state, action) => {
  return updateObject(state, { loading: false, path: "" });
};

const clearCacheStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const clearCacheSuccess = (state, action) => {
  return updateObject(state, { loading: false });
};
const clearCacheFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ADMIN_START:
      return fetchAdminStart(state, action);
    case actionTypes.FETCH_ADMIN_SUCCESS:
      return fetchAdminSuccess(state, action);
    case actionTypes.FETCH_ADMIN_FAIL:
      return fetchAdminFail(state, action);
    case actionTypes.ADMIN_LOGOUT:
      return adminLogout(state, action);
    case actionTypes.CLEAR_CACHE_START:
      return clearCacheStart(state, action);
    case actionTypes.CLEAR_CACHE_SUCCESS:
      return clearCacheSuccess(state, action);
    case actionTypes.CLEAR_CACHE_FAIL:
      return clearCacheFail(state, action);
    default:
      return state;
  }
};

export default reducer;
