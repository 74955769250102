import React from 'react';
import FacilityPreview from '../FacilityPreview/FacilityPreview';
import classes from './FacilitiesWrapper.module.scss';

const FacilitiesWrapper = (props) => {
  const groupAndSortFacilities = (facilities) => {
    const grouped = facilities.reduce((acc, facility) => {
      const division = facility.division;
      if (!acc[division]) {
        acc[division] = [];
      }
      acc[division].push(facility);
      return acc;
    }, {});

    const groupedArray = Object.keys(grouped).map(division => ({
      header: division,
      facilities: grouped[division].sort((a, b) => {
        if (a.name === b.name) {
          return a.weight - b.weight; // Sort by weight if names are the same
        } else {
          return a.name.localeCompare(b.name); // Sort by name
        }
      })
    }));

    // Sort headers, prioritizing those containing "Corporate"
    groupedArray.sort((a, b) => {
      const aIsCorporate = a.header.includes("Corporate");
      const bIsCorporate = b.header.includes("Corporate");
      if (aIsCorporate && !bIsCorporate) {
        return -1;
      } else if (!aIsCorporate && bIsCorporate) {
        return 1;
      } else {
        return a.header.localeCompare(b.header); // Sort the rest alphabetically
      }
    });

    return groupedArray;
  };

  const groupedFacilities = groupAndSortFacilities(props.facilities || []);

  return (
      <div>
        {groupedFacilities.map((group, index) => (
            <div key={index}>
              <h2>{group.header}</h2>
              <div className={classes.facilityList}>
                {group.facilities.map((facility, index) => (
                    <FacilityPreview key={index} facility={facility} />
                ))}
              </div>
            </div>
        ))}
      </div>
  );
};
export default FacilitiesWrapper;
