import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  facilities: [],
  loading: true,
};

const fetchFacilitiesStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchFacilitiesSuccess = (state, action) => {
  return updateObject(state, {
    jumbotronData: action.facilities.banners,
    facilities: action.facilities.facilities,
    sidebarSquares: [
      {
        square_title: "Corporate Video",
        field_background_shading: "Transparent",
        field_horizontal_alignment: "center",
        field_vertical_alignment: "center",
        field_caption:
          "Learn about our company, philosophy, and the people behind our products",
        field_caption_position: "left",
        landing_page_assigned: ["Corporate Home Page"],
        field_weight_value: "16",
        field_icon: [
          "https://cdn-assets.greatplainsmfg.com/ag_files/iconography/corporate_video_icon.png",
        ],
        field_background_image:
          "https://cdn-assets.greatplainsmfg.com/ag_files/styles/scale_1300_wide/public/squares/corporate_video_square_13kpx.jpeg",
        show_on_micro_site_landing_pages: [],
        field_link_square: "https://youtu.be/G09L9KHFyOU",
        nid: "12845",
      },
      {
        square_title: "Corporate Profile",
        field_background_shading: "Darker",
        field_horizontal_alignment: "left",
        field_vertical_alignment: "top",
        field_caption:
          "Download a PDF version of our corporate profile information",
        field_caption_position: "left",
        landing_page_assigned: ["Corporate Home Page"],
        field_weight_value: "18",
        field_icon: [
          "https://cdn-assets.greatplainsmfg.com/ag_files/iconography/corporate_profile_icon.png",
        ],
        field_background_image:
          "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/GreatPlainsCorp-PhotoCollage.jpg",
        show_on_micro_site_landing_pages: [],
        field_link_square:
          "https://assets.greatplainsmfg.com/pdf/corp/corporateprofile-current.pdf",
        nid: "12846",
      },
    ],
    loading: false,
    error: false,
  });
};

const fetchFacilitiesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FACILITIES_START:
      return fetchFacilitiesStart(state, action);
    case actionTypes.FETCH_FACILITIES_SUCCESS:
      return fetchFacilitiesSuccess(state, action);
    case actionTypes.FETCH_FACILITIES_FAIL:
      return fetchFacilitiesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
