import * as actionTypes from "./actionTypes";
import axios from "../../axios-api";

export const fetchAdminStart = () => {
  return {
    type: actionTypes.ADMIN,
  };
};

export const fetchAdminSuccess = (config) => {
  return {
    type: actionTypes.FETCH_ADMIN_SUCCESS,
    config: config,
  };
};

export const fetchAdminFail = (error) => {
  return {
    type: actionTypes.FETCH_ADMIN_FAIL,
    error: error,
  };
};

export const adminLogout = () => {
  return {
    type: actionTypes.ADMIN_LOGOUT,
  };
};

export const fetchAdmin = (token) => {
  return (dispatch) => {
    dispatch(fetchAdminStart());
    const authData = {
      token: token,
      callingAgent: "react-web-app",
    };
    let url = "/admin";

    axios
      .post(url, authData)
      .then((response) => {
        console.log(response);
        dispatch(fetchAdminSuccess(response.data));
      })
      .catch((err) => {
        // console.log(err)
      });
  };
};

export const clearCacheStart = () => {
  return {
    type: actionTypes.CLEAR_CACHE_START,
  };
};

export const clearCacheSuccess = () => {
  return {
    type: actionTypes.CLEAR_CACHE_SUCCESS,
  };
};

export const clearCacheFail = () => {
  return {
    type: actionTypes.CLEAR_CACHE_FAIL,
  };
};

export const clearApiCache = (token) => {
  return (dispatch) => {
    dispatch(clearCacheStart());

    // console.log(token)
    let url = "/clear";
    axios
      .post(url, { token: token })
      .then((response) => {
        dispatch(clearCacheSuccess());
        // console.log(response)
        alert("cache has been cleared!");
      })
      .catch((err) => {
        dispatch(clearCacheFail());
        // console.log(err)
      });
  };
};
