import React from "react";
import classes from "./DivisionPreview.module.scss";
import Parser from "html-react-parser";

export default function DivisionPreview(props) {
  return (
    <div key={props.id} className={classes.Wrapper}>
      {/* <div className={classes.Image} style={style}> </div> */}

      <div className={classes.ContentWrapper}>
        <div className={classes.Header}>
          <h3>{props.division.title}</h3>
          <div className={classes.SubDivision}>{props.division.division}</div>
        </div>

        <div className={classes.Content}>
          <div className={classes.Text}>{Parser(props.division.body)}</div>
          {props.division.link_target != null ? (
            <button className={classes.Button}>
              <a href={props.division.link_target}>
                VISIT {props.division.title}
              </a>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
