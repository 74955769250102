import React from "react";
import Parser from "html-react-parser";
import classes from "./Panel.module.scss";
import templates from "../../../shared/scss/components/Jumbotron/JumbotronTemplates.module.scss";
import { buildClassString } from "../../../shared/utility";
import LearnMoreButton from "../../LearnMoreButton/LearnMoreButton";

/** DocBlock
 * Authored by KylerB
 *
 * @param  {object} props
 * @property {string} banner_id
 * @property {string} background_image
 * @property {int} nid
 * @property {int} weight
 * @property {string} super_text
 * @property {html} title
 * @property {string} caption
 * @property {string} template
 * @property {string} bg_horiz_align
 * @property {string} bg_vert_align
 * @property {string} caption_align
 * @property {string} link_target
 */
export default function Panel(props) {
  let hasButton;
  //arrays that will be used to organize which classes get pushed onto the components
  let wrapperClassList = [classes.Wrapper];
  let cardClassList = [classes.AllCards];
  //image that will be used in the background of the jumbotrons
  let imageUrl = props.data.banner_image.values[0].images["1300px_uri"];
  let bgImageStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: `${props.data.vertical_alignment.value} ${props.data.horizontal_alignment.value}`,
  };

  if (props.button != undefined) {
    hasButton = false;
  } else hasButton = true;

  // /* Sets the caption block alignment. */
  const switchBlockAlignment = (position) => {
    switch (position) {
      case "center":
        return classes.HorizCenter;
      case "right":
        return classes.HorizRight;
      default:
        return classes.HorizLeft;
    }
  };

  // /* If data.template is null, it gets cookie cutter layout,
  //  * otherwise, it uses the classes CustomTemplate & CustomWrapper */
  if (props.data.template == null) {
    wrapperClassList.push(classes.StandardWrapper);
    cardClassList.push(classes.Card);
    wrapperClassList.push(
      switchBlockAlignment(props.data.field_caption_position)
    );
  } else {
    cardClassList.push(templates[props.data.template]);
    wrapperClassList.push(templates[props.data.template + "Wrapper"]);
  }

  // //turns the array of items into a string of module classes
  const cardClassString = buildClassString(cardClassList);
  const wrapperClassString = buildClassString(wrapperClassList);

  return (
    <div className={wrapperClassString} style={bgImageStyle}>
      {props.data.image_only != undefined &&
        props.data.image_only.value != undefined &&
        props.data.image_only.value != true && (
          <div className={cardClassString}>
            {/*{" "}*/}
            {/*/!* Card *!/*/}
            {/*/!*<h3>{props.data.caption.value}</h3>*!/*/}
            <h2>
              {props.data.banner_title &&
              props.data.banner_title.value != undefined
                ? Parser(props.data.banner_title.value)
                : null}
            </h2>
            <p>
              {props.data.caption && props.data.caption.value != undefined
                ? Parser(props.data.caption.value)
                : null}
            </p>
            {props.data.read_more_link != null &&
              props.data.read_more_link.value != undefined &&
              hasButton && (
                <LearnMoreButton buttonLink={props.data.read_more_link.value} />
              )}
          </div>
        )}
    </div>
  );
}
