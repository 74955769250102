import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavigationItem.module.scss";

let $iconClass;

function switcher(prop) {
  switch (prop) {
    case "home":
      $iconClass = classes.HomeIcon;
      break;
    case "current":
      $iconClass = classes.CurrentSiteIcon;
      break;
    case "lock":
      $iconClass = classes.LockIcon;
      break;
  }
}

const navigationItem = (props) => (
  <Fragment>
    {switcher(props.icon)}
    <li className={classes.NavigationItem + " " + $iconClass}>
      {props.link ? (
        <NavLink
          to={props.link}
          exact={props.exact}
          activeClassName={classes.active}
        >
          {props.children}
        </NavLink>
      ) : (
        <a href={props.href} target="_blank">
          {props.children}
        </a>
      )}
    </li>
  </Fragment>
);

export default navigationItem;
