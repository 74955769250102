import React, { Fragment } from "react";
import SidebarSquare from "./SidebarSquare/SidebarSquare";

export default function SidebarSquares(props) {
  return (
    <Fragment>
      {props.squares.map((element) => (
        <div key={element.nid}>
          <SidebarSquare square={element} />
        </div>
      ))}
    </Fragment>
  );
}
