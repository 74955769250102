import React from "react";

import CompanyLogo from "../../assets/images/gp-logo-corp-225px.png";
import HeaderLogo from "../../assets/images/logo-header.png";
import FooterLogo from "../../assets/images/logo-footer.png";
import classes from "./Logo.module.scss";
import { NavLink } from "react-router-dom";
let LogoClass = null;

const switcher = (type) => {
  switch (type) {
    case "header":
      return classes.Header;
    case "footer":
      return classes.Footer;
    default:
      return null;
  }
};

export default function Logo(props) {
  LogoClass = switcher(props.logoType);
  return (
    <div onClick={props.closed} className={classes.Logo}>
      <NavLink to="/">
        <div className={`${classes.ImageDiv} ${LogoClass}`}>
          <img className={classes.Image} src={CompanyLogo} alt="Logo" />
        </div>
      </NavLink>
    </div>
  );
}
