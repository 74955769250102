import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  article: [],
  loading: true,
};

const fetchArticleStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchArticleSuccess = (state, action) => {
  return updateObject(state, {
    article: action.article,
    loading: false,
    error: false,
  });
};

const fetchArticleFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ARTICLE_START:
      return fetchArticleStart(state, action);
    case actionTypes.FETCH_ARTICLE_SUCCESS:
      return fetchArticleSuccess(state, action);
    case actionTypes.FETCH_ARTICLE_FAIL:
      return fetchArticleFail(state, action);
    default:
      return state;
  }
};

export default reducer;
