import React from "react";
import classes from "./PrivacyPolicy.module.scss";

function PolicyCreate() {
  OneTrust.NoticeApi.Initialized.then(function () {
    OneTrust.NoticeApi.LoadNotices(
      [
        "https://privacyportal-cdn.onetrust.com/d99fbdeb-4a2f-4eb6-8cc1-33036f3b7933/privacy-notices/33e3cf7c-d247-4fa2-b94f-faaa0624bbd5.json",
      ],
      false
    );
  });
}

export default class TermsPolicy extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    PolicyCreate();
  }

  render() {
    return (
      <div className={classes.Wrapper}>
        <div className="otnotice-language-dropdown-container">
          <select
            id="otnotice-language-dropdown"
            aria-label="language selector"
          ></select>
        </div>

        <div
          id="otnotice-33e3cf7c-d247-4fa2-b94f-faaa0624bbd5"
          className="otnotice"
        ></div>
      </div>
    );
  }
}
