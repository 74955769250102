import React from "react";
import classes from "./PrivacyPolicy.module.scss";

function PolicyCreate() {
  OneTrust.NoticeApi.Initialized.then(function () {
    OneTrust.NoticeApi.LoadNotices([
      "https://privacyportal-cdn.onetrust.com/d99fbdeb-4a2f-4eb6-8cc1-33036f3b7933/privacy-notices/7d36eeef-b5e4-4ba7-9de0-54a96724d437.json",
    ]);
  });
}

export default class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    PolicyCreate();
  }

  render() {
    return (
      <div className={classes.Wrapper}>
        <div
          id="otnotice-7d36eeef-b5e4-4ba7-9de0-54a96724d437"
          className="otnotice"
        ></div>
      </div>
    );
  }
}
