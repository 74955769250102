import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  missionStatement: {},
  loading: true,
};

const fetchMissionStatementStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchMissionStatementSuccess = (state, action) => {
  // console.log('missionStatement success action', action)
  return updateObject(state, {
    missionStatement: action.missionStatement,
    loading: false,
  });
};

const fetchMissionStatementFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MISSIONSTATEMENT_START:
      return fetchMissionStatementStart(state, action);
    case actionTypes.FETCH_MISSIONSTATEMENT_SUCCESS:
      return fetchMissionStatementSuccess(state, action);
    case actionTypes.FETCH_MISSIONSTATEMENT_FAIL:
      return fetchMissionStatementFail(state, action);
    default:
      return state;
  }
};

export default reducer;
