import React, { Fragment } from "react";
import classes from "./SideDrawer.module.scss";
import NavigationItems from "./NavigationItems/NavigationItems";
import Icon from "../../assets/images/file-lines-solid.svg";
import { Link } from "react-router-dom";

const sideDrawer = (props) => {
  let attachedClasses = [classes.SideDrawer, classes.Close];
  let attachedClassesSecondary = [classes.ExtraContent, classes.Close];
  let attachedToggle = [classes.ToggleButton, classes.Right];

  if (props.open) {
    attachedClasses = [classes.SideDrawer, classes.Open];
  }

  if (props.moreOpen) {
    attachedClassesSecondary = [classes.ExtraContent, classes.Open];
    attachedToggle = [classes.ToggleButton, classes.Down];
  }

  return (
    <Fragment>
      {/* <Backdrop show={props.open} clicked={props.closed} /> */}
      <div className={attachedClasses.join(" ")}>
        <div>
          <ul className={classes.List}>
            <NavigationItems type={"primary"} closed={props.closed} />
          </ul>

          <button
            className={attachedToggle.join(" ")}
            onClick={props.moreToggle}
          >
            {props.moreOpen ? "Less" : "More"}
          </button>
          <ul
            className={attachedClassesSecondary.join(" ")}
            onClick={props.closed}
          >
            <NavigationItems type={"secondary"} />
          </ul>
          <ul className={classes.List}>
            <NavigationItems type={"tertiary"} closed={props.closed} />

            <Link to={"/statement-on-ukraine"} className={classes.Incident}>
              <img className={classes.ICON} src={Icon} /> Statement on Ukraine
            </Link>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default sideDrawer;
