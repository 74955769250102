import * as actionTypes from "./actionTypes";
import axios from "../../axios-api";

export const fetchArticleStart = () => {
  return {
    type: actionTypes.FETCH_ARTICLE_START,
  };
};

export const fetchArticleSuccess = (article) => {
  return {
    type: actionTypes.FETCH_ARTICLE_SUCCESS,
    article: article,
  };
};

export const fetchArticleFail = (error) => {
  return {
    type: actionTypes.FETCH_ARTICLE_FAIL,
    error: error,
  };
};

export const fetchArticle = () => {
  return (dispatch) => {
    dispatch(fetchArticleStart());
    axios
      .get("/v1/articles/single/13349")
      .then((res) => {
        dispatch(fetchArticleSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchArticleFail(err));
      });
  };
};
