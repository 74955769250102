import axios from "axios";

const getEnvironment = () =>
  //todo replace this dynamically
  process.env.NODE_ENV != "development"
    ? "https://api.greatplainsag.com"
    : "http://localhost:8000"; //"http://localhost:8000/mfg/v1/";

let apiUrl = getEnvironment();
let instance = axios.create({ baseURL: apiUrl });
instance.defaults.timeout = 60000;
export default instance;
