import * as actionTypes from "./actionTypes";
// import axios from 'axios'
import YAML from "js-yaml";
import YamlPath from "../../config/yaml/headers.yml";

export const fetchHeaderStart = () => {
  return {
    type: actionTypes.HEADER,
  };
};

export const fetchHeaderSuccess = (header) => {
  return {
    type: actionTypes.FETCH_HEADER_SUCCESS,
    header: header,
  };
};

export const fetchHeaderFail = (error) => {
  return {
    type: actionTypes.FETCH_HEADER_FAIL,
    error: error,
  };
};

export const fetchHeader = () => {
  return (dispatch) => {
    dispatch(fetchHeaderStart());
    fetch(YamlPath)
      .then((r) => r.text())
      .then((text) => {
        const doc = YAML.load(text);
        // console.log(doc)
        dispatch(fetchHeaderSuccess(doc));
      })
      .catch((err) => {
        // console.log('the header fetch messed up')
        dispatch(fetchHeaderFail(err));
      });
  };
};
