import * as actionTypes from "./actionTypes";
// import axios from 'axios'
import axios from "../../axios-api";

export const fetchHistoryStart = () => {
  return {
    type: actionTypes.HISTORY,
  };
};

export const fetchHistorySuccess = (history) => {
  return {
    type: actionTypes.FETCH_HISTORY_SUCCESS,
    history: history,
  };
};

export const fetchHistoryFail = (error) => {
  return {
    type: actionTypes.FETCH_HISTORY_FAIL,
    error: error,
  };
};

export const fetchHistory = () => {
  return (dispatch) => {
    dispatch(fetchHistoryStart());
    axios
      .get("/mfg/v1/history")
      .then((res) => {
        // console.log('response')
        // console.log(res)
        dispatch(fetchHistorySuccess(res.data));
      })
      .catch((err) => {
        // console.log(err)
        // console.log('err')
        dispatch(fetchHistoryFail(err));
      });
  };
};
