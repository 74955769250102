import React from "react";
import { Link } from "react-router-dom";
import classes from "./HomeBanner.module.scss";
import image from "../../../assets/images/components/homeBanner.png";
export default function HomeBanner() {
  return (
    <div className={classes.Wrapper}>
      <div className={classes.Card}>
        <h1>
          <span>Welcome</span> to Great Plains Manufacturing's Corporate Home
        </h1>

        <p>
            Great Plains Manufacturing, Inc., was established on April 1, 1976 by company founder Roy Applequist. Since our inception, Great Plains has become a leader in the manufacturing of agricultural implements for tillage, seeding, and planting in the United States, as well as a leading producer of dirtworking, turf maintenance, and landscaping equipment. Now a Kubota Company, Great Plains comprises four divisions: Great Plains Ag, Land Pride, Great Plains Trucking, and Great Plains Construction Equipment.
        </p>

        {/* This span is here because the arrow was floating right as a background on the element because of line breaks.. */}
        <Link className={classes.Link} to="/history">
          Learn More About Our Company History<span></span>
        </Link>
      </div>

      <div className={classes.Background}>
        <img className={classes.Image} src={image} />
      </div>
    </div>
  );
}
