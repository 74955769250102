import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { Redirect, withRouter } from "react-router-dom";

import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
import classes from "./Auth.module.scss";
import * as actions from "../../store/actions/index";
import { updateObject, checkValidity } from "../../shared/utility";

class Auth extends Component {
  state = {
    controls: {
      username: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "User Account",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Password",
        },
        value: "",
        validation: {
          required: true,
          minLength: 6,
        },
        valid: false,
        touched: false,
      },
    },
  };

  componentDidMount() {
    // if (this.props.authRedirectPath !== '/') {
    // this.props.onSetAuthRedirectPath();
    // }
  }

  inputChangedHandler = (event, controlName) => {
    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true,
      }),
    });
    this.setState({ controls: updatedControls });
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.props.onAuth(
      this.state.controls.username.value,
      this.state.controls.password.value
    );
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    let errorMessage = null;
    if (this.props.error) {
      errorMessage = (
        <Fragment>
          <h3>An Error Has Occured</h3>
          <p>{this.props.error.details}</p>
        </Fragment>
      );
    }

    let loginMessage = !this.props.error ? (
      <h3>Please Log In</h3>
    ) : (
      <h3>Please Try Again</h3>
    );

    let form = <Spinner />;

    if (!this.props.loading) {
      form = (
        <div>
          {authRedirect}
          {errorMessage}
          <form className={classes.Auth} onSubmit={this.submitHandler}>
            {loginMessage}

            {formElementsArray.map((formElement) => (
              <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) =>
                  this.inputChangedHandler(event, formElement.id)
                }
              />
            ))}
            <Button btnType="Success"> SUBMIT</Button>
          </form>
        </div>
      );
    }

    let authRedirect = null;
    if (this.props.authenticated) {
      console.log("testing the authRedirectPath", this.props.authRedirectPath);
      authRedirect = <Redirect to="/admin" />;
    }

    return (
      <div>
        {form}
        {authRedirect}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    authenticated: state.auth.authenticated,
    // isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) =>
      dispatch(actions.authenticate(username, password)),
    // onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
