import React, { Fragment } from 'react';
import classes from './ToTop.module.scss';

function scrollTop() {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

function handleEnter(e) { 
  if (e.key==='Enter'){
    scrollTop();
  }
}

const toTop = (props) => (
  <a tabIndex="0" onClick={scrollTop} onKeyUp={handleEnter} className={classes.ToTop}>
    BACK TO TOP
  </a>
);

export default toTop;