export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const REAUTHORIZATION_SUCCESS = "REAUTHORIZATION_SUCCESS";
export const REAUTHORIZATION_FAILURE = "REAUTHORIZATION_FAILURE";

export const TEMP_AUTH_SUCCESS = "TEMP_AUTH_SUCCESS";
export const AUTH = "AUTH";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REFRESH = "AUTH_REFRESH";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const FETCH_FACILITIES_START = "FETCH_FACILITIES_START";
export const FETCH_FACILITIES_SUCCESS = "FETCH_FACILITIES_SUCCESS";
export const FETCH_FACILITIES_FAIL = "FETCH_FACILITIES_FAIL";
export const FACILITIES = "FACILITIES";

export const FETCH_HISTORY_START = "FETCH_HISTORY_START";
export const FETCH_HISTORY_SUCCESS = "FETCH_HISTORY_SUCCESS";
export const FETCH_HISTORY_FAIL = "FETCH_HISTORY_FAIL";
export const HISTORY = "HISTORY";

export const FETCH_ADMIN_START = "FETCH_ADMIN_START";
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS";
export const FETCH_ADMIN_FAIL = "FETCH_ADMIN_FAIL";
export const ADMIN_LOGOUT = "AUTH_LOGOUT";
export const ADMIN = "ADMIN";

export const CLEAR_CACHE_START = "CLEAR_CACHE_START";
export const CLEAR_CACHE_SUCCESS = "CLEAR_CACHE_SUCCESS";
export const CLEAR_CACHE_FAIL = "CLEAR_CACHE_FAIL";

export const FETCH_HEADER_START = "FETCH_HEADER_START";
export const FETCH_HEADER_SUCCESS = "FETCH_HEADER_SUCCESS";
export const FETCH_HEADER_FAIL = "FETCH_HEADER_FAIL";
export const HEADER = "HEADER";

export const FETCH_NAV_START = "FETCH_NAV_START";
export const FETCH_NAV_SUCCESS = "FETCH_NAV_SUCCESS";
export const FETCH_NAV_FAIL = "FETCH_NAV_FAIL";
export const NAV = "NAV";

export const FETCH_MISSIONSTATEMENT_START = "FETCH_MISSIONSTATEMENT_START";
export const FETCH_MISSIONSTATEMENT_SUCCESS = "FETCH_MISSIONSTATEMENT_SUCCESS";
export const FETCH_MISSIONSTATEMENT_FAIL = "FETCH_MISSIONSTATEMENT_FAIL";
export const MISSIONSTATEMENT = "MISSIONSTATEMENT";

export const FETCH_DIVISIONS_START = "FETCH_DIVISIONS_START";
export const FETCH_DIVISIONS_SUCCESS = "FETCH_DIVISIONS_SUCCESS";
export const FETCH_DIVISIONS_FAIL = "FETCH_DIVISIONS_FAIL";
export const DIVISIONS = "DIVISIONS";

export const FETCH_HOME_START = "FETCH_HOME_START";
export const FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS";
export const FETCH_HOME_FAIL = "FETCH_HOME_FAIL";
export const HOME = "HOME";

export const FETCH_ARTICLE_START = "FETCH_ARTICLE_START";
export const FETCH_ARTICLE_SUCCESS = "FETCH_ARTICLE_SUCCESS";
export const FETCH_ARTICLE_FAIL = "FETCH_ARTICLE_FAIL";
export const ARTICLE = "ARTICLE";
