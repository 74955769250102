import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./store/actions/index";
import Layout from "./hoc/Layout/Layout";
import Home from "./containers/Home/Home";
import Auth from "./containers/Auth/Auth";
import Logout from "./containers/Auth/Logout/Logout";
import Facilities from "./containers/Facilities/Facilities";
import Divisions from "./containers/Divisions/Divisions";
import History from "./containers/History/History";
import CompanyWebsites from "./components/CompanyWebsites/CompanyWebsites";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "./components/PrivacyPolicy/CookiePolicy";
import TermsOfUse from "./components/PrivacyPolicy/Terms";
import Admin from "./containers/Admin/Admin";
import Ukraine from "./components/ukraine/ukraine";
import Article from "./components/Article/article";

class App extends Component {
  componentDidMount() {
    this.props.onReauthenticate();
    this.props.onFetchNav();
  }

  render() {
    //these are only available when logged in
    let protectedPaths = [
      <Route key="logout" path="/logout" component={Logout} />,
    ];

    //only available when not logged in
    let unprotectedPaths = [];

    //always available
    let publicPaths = [
      <Route
        key="facilities"
        exact
        path="/facilities"
        component={Facilities}
      />,
      <Route key="history" exact path="/history" component={History} />,
      <Route key="divisions" exact path="/divisions" component={Divisions} />,
      <Route
        key="company-websites"
        exact
        path="/company-websites"
        component={CompanyWebsites}
      />,
      <Route
        key="privacy-policy"
        exact
        path="/privacy-policy"
        component={PrivacyPolicy}
      />,
      <Route
        key="cookie-policy"
        exact
        path="/cookie-policy"
        component={CookiePolicy}
      />,
      <Route
        key="terms-of-use-policy"
        exact
        path="/terms-of-use-policy"
        component={TermsOfUse}
      />,
      <Route
        key="statement-on-ukraine"
        exact
        path="/statement-on-ukraine"
        component={Ukraine}
      />,

      <Route key="node" exact path="/scholars" component={Article} />,

      <Route key="index" path="/" exact component={Home} />,
      <Route key="admin" path="/admin" exact component={Admin} />,
      <Route key="user" path="/user" component={Auth} />,
    ];

    let allowedPaths = (localStorage.getItem("token")
      ? protectedPaths
      : unprotectedPaths
    ).concat(publicPaths);
    let paths = allowedPaths.map((element, index) => element);

    return (
      <div>
        <Layout>
          <Switch>
            {paths}
            <Redirect to="/" />
          </Switch>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onReauthenticate: () => dispatch(actions.checkForToken()),
    onFetchNav: () => dispatch(actions.fetchNav()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
