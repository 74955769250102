import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LinkSwitch from "./../../LinkSwitch/LinkSwitch";
import iconClasses from "../../Navigation/Icons.module.scss";
import classes from "./../SideDrawer.module.scss";
// import NavigationItem from './NavigationItem/NavigationItem';

class NavigationItems extends Component {
  render() {
    //will render a loading icon if the data isn't loaded in yet
    let navItems = <div>loading</div>;
    if (!this.props.loading) {
      this.props.navItems.forEach((navList) => {
        if (navList.purpose == this.props.type) {
          navItems = navList.links.map((element, index) => {
            if (element.showInHeader) {
              let linkClass = classes.Regular;
              if (element.icon != null) {
                linkClass = `${classes.IconLink} ${
                  iconClasses[element.icon + "SideDrawer"]
                }`;
              }
              return (
                <li key={index} onClick={this.props.closed}>
                  <LinkSwitch
                    linkClass={linkClass}
                    text={element.text}
                    link={element.link}
                  />
                </li>
              );
            }
          });
        }
      });
    }
    return <Fragment>{navItems}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    navItems: state.nav.nav.navLinks,
    loading: state.nav.loading,
  };
};

export default connect(mapStateToProps)(NavigationItems);
