import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "axios";
import Spinner from "../../components/UI/Spinner/Spinner";
import classes from "./Admin.module.scss";

class Admin extends Component {
  componentDidMount() {
    if (this.props.token) {
      this.props.onFetchAdmin(this.props.token);
    }
  }

  render() {
    //will render a loading icon if the data isn't loaded in yet
    let admin = <Spinner />;
    if (!this.props.loading) {
      admin = (
        <div className={classes.Wrapper}>
          <div className={classes.Panel}>
            <h1>Admin Panel</h1>
            <button
              onClick={() => this.props.onClearApiCache(this.props.token)}
            >
              clearCache
            </button>
            <button>
              <a href={this.props.path}>Go To the CMS</a>
            </button>
          </div>
        </div>
      );
    }

    if (!this.props.authenticated) {
      admin = <Redirect to="/user" />;
    }

    return <div>{admin}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    config: state.admin.config,
    path: state.admin.path,
    loading: state.admin.loading,
    authenticated: state.auth.authenticated || state.auth.tempAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchAdmin: (token) => dispatch(actions.fetchAdmin(token)),
    onClearApiCache: (token) => dispatch(actions.clearApiCache(token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Admin, axios));
