import React from "react";
import classes from "./Square.module.scss";
import { Link } from "react-router-dom";
import Parser from "html-react-parser";
export default function Square(props) {
  // let imageUrl =
  //   process.env.REACT_APP_IMAGE_URL + props.square.field_background_image;
  let imageUrl = props.square.square_image.values[0].images['1300px_uri'];
  let classList = [classes.Wrapper];

  //setting background style information here because I need to pass variable to it
  let style = {
    // backgroundImage: `linear-gradient(rgba(80,80,80,.2), rgba(80,80,80,.2) ),     url(${imageUrl})`,
    backgroundImage: `url(${imageUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  // adds classes to the square based on their position in the array
  if (props.full == true) {
    classList.push(classes.Full);
    classList.push(props.square.content_location == "left" && classes.FullLeft);
    classList.push(
      props.square.content_location == "right" && classes.FullRight
    );
    classList.push(
      props.square.content_location == "center" && classes.FullCenter
    );
  } else {
    classList.push(classes.Small);
    classList.push(props.side == "left" ? classes.Left : classes.Right);
  }

  let classString = "";
  classList.forEach((element) => {
    classString += element + " ";
  });

  return (
    <div className={classString} style={style}>
      <div className={classes.Content}>
        <div>
          <img className={classes.Icon} src={props.square.icon.values[0].url} />
        </div>
        <div className={classes.Title}>{Parser(props.square.title)}</div>
        <div className={classes.Subtitle}>
          {Parser(props.square.caption.value)}
        </div>
        {props.square.read_more_link.value[0] == "/" ? (
          <Link className={classes.Button} to={props.square.read_more_link.value}>
            LEARN MORE
          </Link>
        ) : (
          <a
            className={classes.Button}
            target="_blank"
            href={props.square.read_more_link.value}
          >
            LEARN MORE
          </a>
        )}
      </div>
    </div>
  );
}
