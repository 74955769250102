import classes from "./CompanyWebsites.module.scss";
import React, { Component } from "react";
export default class CompanyWebsites extends Component {
  componentDidMount() {
    document.title =
      "Great Plains Company Websites | Great Plains Manufacturing";
  }

  render() {
    return (
      <div className={classes.Wrapper}>
        <h1 className={classes.Header}>GREAT PLAINS COMPANY WEBSITES</h1>

        <section className={classes.Section}>
          <div className={classes.ColumnTwo}>
            <div className="division-websites">
              <h3>Division Websites</h3>
              <ul className={classes.UnorderedList}>
                <li className="site-list-item gray-arrow">
                  <a
                    className="site-link gray-arrow"
                    href="https://www.greatplainsmfg.com"
                  >
                    Great Great Plains Corporate{" "}
                  </a>
                </li>
                <li className="site-list-item gray-arrow">
                  <a
                    className="site-link gray-arrow"
                    href="https://www.landpride.com"
                  >
                    Land Pride
                  </a>
                </li>
                <li className="site-list-item gray-arrow">
                  <a
                    className="site-link gray-arrow"
                    href="https://www.greatplainsag.com"
                  >
                    Great Plains Ag
                  </a>
                </li>
                <li className="site-list-item gray-arrow">
                  <a
                    className="site-link gray-arrow"
                    href="https://www.greatplainsag.com/en-gb/"
                  >
                    Great Plains International
                  </a>
                </li>

                <li className="site-list-item gray-arrow">
                  <a
                    className="site-link gray-arrow"
                    href="https://www.gptrucking.com"
                  >
                    Great Plains Trucking
                  </a>
                </li>
              </ul>
            </div>
            <div className="other-websites">
              <h3>Other Websites</h3>
              <ul className={classes.UnorderedList}>
                <li className="site-list-item gray-arrow">
                  <a
                    className="site-link gray-arrow"
                    href="https://dealers.greatplainsmfg.com/dealer_access/"
                  >
                    Great Plains Dealer Access
                  </a>
                </li>
                <li className="site-list-item gray-arrow">
                  <a
                    className="site-link gray-arrow"
                    href="https://dealers.landpride.com/dealer_access/"
                  >
                    Land Pride Dealer Access
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
