import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import "./index.scss";
import App from "./App";
// import registerServiceWorker from './registerServiceWorker';
import authReducer from "./store/reducers/auth";
import facilitiesReducer from "./store/reducers/facilities";
import divisionsReducer from "./store/reducers/divisions";
import historyReducer from "./store/reducers/history";
import adminReducer from "./store/reducers/admin";
import headerReducer from "./store/reducers/header";
import navReducer from "./store/reducers/nav";
import missionStatementReducer from "./store/reducers/missionStatement";
import homeReducer from "./store/reducers/home";
import articleReducer from "./store/reducers/articles";
import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";

const composeEnhancers =
  process.env.NODE_ENV === "developments"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;
// const composeEnhancers = compose;

const rootReducer = combineReducers({
  auth: authReducer,
  facilities: facilitiesReducer,
  divisions: divisionsReducer,
  history: historyReducer,
  header: headerReducer,
  admin: adminReducer,
  home: homeReducer,
  missionStatement: missionStatementReducer,
  nav: navReducer,
  article: articleReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
// registerServiceWorker();
