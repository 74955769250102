import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'

export default function LinkSwitch(props) {
  let linkClass = null;
  if (props.linkClass != null){
    linkClass = props.linkClass
  }
  return (


    <Fragment>
      {props.link[0] == "/" ?
      <Link className={linkClass} to={props.link}>{props.text}</Link>
      :
      <a className={linkClass} target="_blank" href={props.link}>{props.text}</a>}
      </Fragment>
  )

}