import React from "react";
import classes from "./Primary.module.scss";
import Logo from "../../../Logo/Logo";
import NavigationItems from "./NavigationItems/NavigationItems";
import DrawerToggle from "../../../SideDrawer/DrawerToggle/DrawerToggle";

const primary = (props) => (
  <div className={classes.Primary}>
    <div className={classes.PrimaryNav}>
      <Logo logoType={"header"} closed={props.closed} />

      <NavigationItems isAuthenticated={props.isAuth} />
      <DrawerToggle open={props.open} clicked={props.drawerToggleClicked} />
    </div>
  </div>
);

export default primary;
