import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  divisions: [],
  loading: true,
  error: false,
};

const fetchDivisionsStart = (state, action) => {
  return updateObject(state, { loading: true, error: false });
};

const fetchDivisionsSuccess = (state, action) => {
  return updateObject(state, {
    jumbotronData: action.divisions.banners,
    header: action.divisions.header,
    divisions: action.divisions.divisions,
    sidebarSquares: [
      {
        square_title: "Corporate Video",
        field_background_shading: "Transparent",
        field_horizontal_alignment: "center",
        field_vertical_alignment: "center",
        field_caption:
          "Learn about our company, philosophy, and the people behind our products",
        field_caption_position: "left",
        landing_page_assigned: ["Corporate Home Page"],
        field_weight_value: "16",
        field_icon: [
          "https://cdn-assets.greatplainsmfg.com/ag_files/iconography/corporate_video_icon.png",
        ],
        field_background_image:
          "https://cdn-assets.greatplainsmfg.com/ag_files/styles/scale_1300_wide/public/squares/corporate_video_square_13kpx.jpeg",
        show_on_micro_site_landing_pages: [],
        field_link_square: "https://youtu.be/G09L9KHFyOU",
        nid: "12845",
      },
    ],
    loading: false,
  });
};

const fetchDivisionsFail = (state, action) => {
  return updateObject(state, { loading: false, error: true });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DIVISIONS_START:
      return fetchDivisionsStart(state, action);
    case actionTypes.FETCH_DIVISIONS_SUCCESS:
      return fetchDivisionsSuccess(state, action);
    case actionTypes.FETCH_DIVISIONS_FAIL:
      return fetchDivisionsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
