// import * as actions from '../../store/actions/index';
// import { withRouter } from 'react-router';
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import ToTop from "./ToTop/ToTop";
import classes from "./Footer.module.scss";
import Logo from "./../Logo/Logo";
// import FooterItems from './FooterItems/FooterItems'
import LinkSwitch from "../LinkSwitch/LinkSwitch";
// import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';

function compare(a, b) {
  if (a.weight < b.weight) return -1;
  if (a.weight > b.weight) return 1;
  return 0;
}

class Footer extends Component {
  render() {
    let footer = null;

    let lists = null;

    if (!this.props.loading) {
      this.props.navLinks.sort(compare);
      lists = this.props.navLinks.map(
        (list, index) =>
          list.showInFooter && (
            <div
              className={`${classes.ListWrapper} ${classes.Four}`}
              key={index}
            >
              <div className={classes.Center}>
                <h4>{list.alias}</h4>
                <ul className={classes.GapTop}>
                  {list.links.map((item, index) => (
                    <li key={index}>
                      <LinkSwitch link={item.link} text={item.text} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )
      );

      let infoBlock = (
        <div className={`${classes.InfoBlock} ${classes.Four}`}>
          <div className={classes.Center}>
            <h4>{this.props.info.name}</h4>
            <div className={classes.GapTop}>
              <div>
                {this.props.info.address}
                <br />
                {this.props.info.city}, {this.props.info.state}{" "}
                {this.props.info.zip}
              </div>
            </div>
          </div>
        </div>
      );

      let year = new Date().getFullYear();

      let social = this.props.social.map((element, index) => (
        <a
          className={` ${classes.SocialLink} ${classes[element.type]} `}
          target="_blank"
          key={index}
          href={element.link}
        />
      ));

      let bottomSection = (
        <div className={classes.FooterBottom}>
          <div className={classes.LeftWrap}>
            <div className={classes.LogoWrapper}>
              <Logo logoType={"footer"} />
            </div>
            <div className={classes.Copyright}>
              Copyright &copy; {year}, {this.props.info.name}. All Rights
              Reserved
            </div>
          </div>

          <div className={classes.RightWrap}>
            <div className={classes.Policies}>
              <Link to={"/privacy-policy"}>Privacy Policy</Link>
            </div>
            <div className={classes.Policies}>
              <Link to={"/cookie-policy"}>Cookie Policy</Link>
            </div>
            <div className={classes.Policies}>
              <Link to={"/terms-of-use-policy"}>Terms of Use Policy</Link>
            </div>

            <div className={classes.SocialWrap}>{social}</div>
          </div>
        </div>
      );

      footer = (
        <footer onClick={this.props.closed} className={classes.Footer}>
          <ToTop />

          <div className={classes.MainWrapper}>
            {infoBlock}
            {lists}
          </div>

          <div>{bottomSection}</div>
        </footer>
      );
    }

    return <div>{footer}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    navLinks: state.nav.nav.navLinks,
    info: state.nav.nav.info,
    social: state.nav.nav.social,
    loading: state.nav.loading,
  };
};

// const mapDispatchToProps = dispatch => {
// return {
//
// };
// };

export default connect(mapStateToProps)(Footer);
// export default connect(mapStateToProps, mapDispatchToProps)(Footer);
