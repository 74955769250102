import * as actionTypes from "./actionTypes";
// import axios from 'axios'
import axios from "../../axios-api";

export const fetchFacilitiesStart = () => {
  return {
    type: actionTypes.FACILITIES,
  };
};

export const fetchFacilitiesSuccess = (facilities) => {
  return {
    type: actionTypes.FETCH_FACILITIES_SUCCESS,
    facilities: facilities,
  };
};

export const fetchFacilitiesFail = (error) => {
  return {
    type: actionTypes.FETCH_FACILITIES_FAIL,
    error: error,
  };
};

export const fetchFacilities = () => {
  return (dispatch) => {
    dispatch(fetchFacilitiesStart());
    axios
      .get("/mfg/v1/facilities")
      .then((res) => {
        dispatch(fetchFacilitiesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchFacilitiesFail(err));
      });
  };
};
