import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import React, { Component } from "react";
import Spinner from "../../components/UI/Spinner/Spinner";
import ErrorDisplay from "./../../components/ErrorDisplay/ErrorDisplay";
import classes from "./article.module.scss";
import Parser from "html-react-parser";

class Article extends Component {
  componentDidMount() {
    this.props.onFetchArticle();
  }

  render() {
    let content = <Spinner />;

    // console.log("before chek", this.props)
    if (!this.props.loading && !this.props.article.loading) {
      if (!this.props.error && !this.props.article.error) {
        document.title = `${this.props.article.article.article[0].title} | Great Plains Manufacturing`;
        content = (
          <div className={classes.Wrapper}>
            <h1 className={classes.Header}>
              {this.props.article.article.article[0].title}
            </h1>
            <section className={classes.Section}>
              {Parser(this.props.article.article.article[0].body)}
            </section>
          </div>
        );
      } else {
        content = <ErrorDisplay />;
      }
    }

    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    article: state.article,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchArticle: () => dispatch(actions.fetchArticle()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);
