import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "axios";
import Spinner from "../../components/UI/Spinner/Spinner";
import classes from "./History.module.scss";
import Jumbotron from "./../../components/Jumbotron/Jumbotron";
import Sidebar from "./../Sidebar/Sidebar";
import Parser from "html-react-parser";
import ErrorDisplay from "./../../components/ErrorDisplay/ErrorDisplay";

class History extends Component {
  componentDidMount() {
    this.props.onFetchHistory();
    document.title = "History | Great Plains Manufacturing";
  }

  render() {
    //will render a loading icon if the data isn't loaded in yet
    let content = <Spinner />;
    let jumbotron = null;

    if (!this.props.loading) {
      if (!this.props.error) {
        // goes through each bucket and adds the banner
        jumbotron = (
          <Jumbotron
            jumbotronData={this.props.jumbotronData}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            autoPlay={true}
            stopOnHover={true}
            interval={10000}
            transitionTime={350}
            button={false}
          />
        );

        let header = (
          <div className={`${classes.Padding} ${classes.History}`}>
            <h1 className={classes.Header}>
              {Parser(this.props.header.title)}
            </h1>
            <div className={classes.Body}>{Parser(this.props.header.text)}</div>
          </div>
        );

        let sidebar = (
          <div className={classes.Sidebar}>
            <Sidebar squares={this.props.sidebarSquares} />
          </div>
        );

        content = (
          <Fragment>
            {jumbotron}
            <div className={classes.Wrapper}>
              {header}
              {sidebar}
            </div>
          </Fragment>
        );
      } else {
        content = <ErrorDisplay />;
      }
    }
    //todo add this
    // if(this.props.error){
    //   history = <div >There was an error, catch this</div>
    // }

    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.history.loading,
    error: state.history.error,
    jumbotronData: state.history.jumbotronData,
    header: state.history.header,
    sidebarSquares: state.history.sidebarSquares,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchHistory: () => dispatch(actions.fetchHistory()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(History, axios));
