import React from "react";
import classes from "./FacilityPreview.module.scss";
import Parser from "html-react-parser";


export default function FacilityPreview(props) {

  const style = {
    backgroundImage: `url(${props.facility.thumbnail.uri})`,
    backgroundPosition: `${props.facility.thumbnail.valign} ${props.facility.thumbnail.halign}`,
  };

  return (
    <div className={classes.Wrapper}>
      <div className={classes.Image} style={style}></div>

      <div className={classes.ContentWrapper}>
        <div className={classes.Header}>
          <h3>
            {props.facility.title} - {props.facility.location}
          </h3>
          <div className={classes.SubDivision}>{props.facility.division.replace("Division", '')}</div>
        </div>

        <div className={classes.Content}>
          <div className={classes.Text}>{Parser(props.facility.body)}</div>
        </div>
      </div>
    </div>
  );
}
