import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  token: null,
  refreshToken: null,
  tokenExp: null,
  error: null,
  loading: false,
  authenticated: false,
  tempAuthenticated: false,
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    refreshToken: action.refreshToken,
    tokenExp: action.tokenExp,
    error: null,
    loading: false,
    authenticated: true,
    tempAuthenticated: false,
  });
};

const tempAuthSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    refreshToken: action.refreshToken,
    tokenExp: action.tokenExp,
    error: null,
    loading: false,
    authenticated: false,
    tempAuthenticated: true,
  });
};

const authFailure = (state, action) => {
  return updateObject(state, {
    token: null,
    error: null,
    loading: false,
    authenticated: false,
    tempAuthenticated: false,
  });
};

const reauthorizationSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    refreshToken: action.refreshToken,
    tokenExp: action.tokenExp,
    error: null,
    loading: false,
    authenticated: true,
    tempAuthenticated: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    refreshToken: null,
    tokenExp: null,
    authenticated: false,
    tempAuthenticated: false,
  });
};

const authRefresh = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    error: null,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_REFRESH:
      return authRefresh(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.TEMP_AUTH_SUCCESS:
      return tempAuthSuccess(state, action);

    case actionTypes.REAUTHORIZATION_SUCCESS:
      return reauthorizationSuccess(state, action);
    case actionTypes.REAUTHORIZATION_FAILURE:
      return authFail(state, action);

    // case actionTypes.AUTH_FAIL: return authFail(state, action);
    default:
      return state;
  }
};

export default reducer;
