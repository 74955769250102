import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./NavigationItems.module.scss";
import NavigationItem from "./NavigationItem/NavigationItem";

class navigationItems extends Component {
  render() {
    //will render a loading icon if the data isn't loaded in yet
    let navItems = <div>loading</div>;
    if (!this.props.loading) {
      navItems = this.props.menuItems.map((item, index) =>
        item.type === "link" ? (
          <NavigationItem key={index} link={item.link} exact>
            {item.text}
          </NavigationItem>
        ) : (
          <NavigationItem key={index} href={item.link} exact>
            {item.text}
          </NavigationItem>
        )
      );
    }
    return (
      <div>
        <ul className={classes.NavigationItems}>{navItems}</ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuItems: state.header.header.secondary,
    loading: state.header.loading,
  };
};

export default connect(mapStateToProps)(navigationItems);

// import React, {Fragment} from 'react';
// import classes from './NavigationItems.module.scss';
// import NavigationItem from './NavigationItem/NavigationItem';

// const navigationItems = (props) => (
//     <ul className={classes.NavigationItems}>
//         <NavigationItem link="/facilities">Facilities</NavigationItem>
//         <NavigationItem link="/divisions" exact>Divisions</NavigationItem>
//         <NavigationItem link="/history" exact>History</NavigationItem>
//         <NavigationItem link="/corporate-video" exact>Corporate Video</NavigationItem>
//         <NavigationItem link="/corporate-profile" exact>Corporate Profile</NavigationItem>
//         <NavigationItem href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=31675842-64d3-4850-823a-e37f71fa6c05&ccId=19000101_000001&type=MP&lang=en_US" exact>Careers</NavigationItem>

//         {!props.isAuthenticated
//             ? <NavigationItem link="/user">Log In</NavigationItem>
//             :
//             <Fragment>
//                 <NavigationItem link="/logout">Logout</NavigationItem>
//                 <NavigationItem link="/admin">Admins</NavigationItem>
//             </Fragment>
//         }

//     </ul>
// );

// export default navigationItems;
