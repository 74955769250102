import axios from "../../axios-api";
import * as actionTypes from "./actionTypes";
import { fetchAdmin } from "./index";

const clearSaved = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expirationDate");
};

//what happens when someone tries signing in
export const authenticate = (username, password) => {
  return (dispatch) => {
    dispatch(authStart());
    //gather data from form
    const authData = {
      username: username,
      password: password,
      callingAgent: "react-web-app",
    };
    //path the url sends to
    let url = "/auth/signin";
    //make the post request
    axios
      .post(url, authData)

      .then((response) => {
        // status 200 correct credentials
        const expirationDate = new Date(
          new Date().getTime() + response.data.expires_in * 1000
        );
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        dispatch(
          authSuccess(
            response.data.access_token,
            response.data.refresh_token,
            expirationDate
          )
        );
      })
      .catch((err) => {
        dispatch(authFail(err.response.data));
      });
  };
};

//start the process of loggin in with a password
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

//send to update state on a successful login
// export const authSuccess = (token, userId) => {
export const authSuccess = (token, refresh, expires) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    refreshToken: refresh,
    tokenExp: expires,
  };
};

export const checkForToken = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refresh_token");
    const tokenExp = localStorage.getItem("expirationDate");

    if (token) {
      dispatch(tempAuthSuccess(token, refreshToken, tokenExp));

      if (refreshToken) {
        dispatch(tokenRefresh());
      }
    }
  };
};

export const tempAuthSuccess = (token, refresh, expires) => {
  return {
    type: actionTypes.TEMP_AUTH_SUCCESS,
    idToken: token,
    refreshToken: refresh,
    tokenExp: expires,
  };
};

export const tokenRefresh = () => {
  return (dispatch) => {
    let url = "/auth/refresh";
    const refreshToken = localStorage.getItem("refresh_token");
    axios
      .post(url, { refresh_token: refreshToken })
      .then((response) => {
        // console.log(response)
        const expirationDate = new Date(
          new Date().getTime() + response.data.expires_in * 1000
        );
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        dispatch(
          reauthorizationSuccess(
            response.data.access_token,
            expirationDate,
            response.data.refresh_token
          )
        );
      })
      .catch((err) => {
        dispatch(reauthorizationFailure());
        // console.log(err)
      });
  };
};

export const reauthorizationSuccess = (token, tokenExp, refreshToken) => {
  return {
    type: actionTypes.REAUTHORIZATION_SUCCESS,
    idToken: "token",
    refreshToken: "refreshToken",
    tokenExp: "tokenExp",
  };
};
export const reauthorizationFailure = () => {
  return {
    type: actionTypes.REAUTHORIZATION_FAILURE,
  };
};

export const logout = () => {
  clearSaved();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authCheckState = () => {};

// export const checkAuthTimeout = (expirationTime) => {
//   return dispatch => {
//     expirationTime -= 5
//     setTimeout(() => {
//       if (dispatch(refreshToken())) {

//       }

//     }, Math.max((expirationTime * 1000), 0));
//   };
// };

// export const authRefresh = (token, userId) => {
//     return {
//         type: actionTypes.AUTH_REFRESH,
//         idToken: token,
//         userId: userId
//     };
// };

// export const authFail = (error) => {
//     return {
//         type: actionTypes.AUTH_FAIL,
//         error: error
//     };
// };

// export const logout = () => {
//     localStorage.removeItem('token');
//     localStorage.removeItem('refresh_token');
//     localStorage.removeItem('expirationDate');
//     localStorage.removeItem('userId');
//     return {
//         type: actionTypes.AUTH_LOGOUT
//     };
// };

// export const refreshToken = () => {
//     return dispatch => {
//         console.log('token is being refreshed')
//         localStorage.removeItem('token');
//         localStorage.removeItem('expirationDate');

//         const authData = {
//             refresh_token: localStorage.getItem('refresh_token'),
//             userId: localStorage.getItem('userId'),
//             callingAgent: 'react-web-app'
//         };

//         let url = '/auth/refresh';

//         axios.post(url, authData)
//             .then(response => {
//                 console.log('success response from server for refresh token')
//                 const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);
//                 localStorage.setItem('token', response.data.access_token);
//                 localStorage.setItem('expirationDate', expirationDate);
//                 localStorage.setItem('userId', response.data.userId);
//                 localStorage.setItem('refresh_token', response.data.refresh_token);
//                 dispatch(authRefresh(response.data.access_token, response.data.userId));
//                 dispatch(checkAuthTimeout(response.data.expires_in));
//                 return true
//             })
//             .catch(err => {
//                 console.log('err from server on refresh token')
//                 dispatch(logout())
//                 dispatch(authFail(err.response.data.error));
//             });
//     };
// };

// export const setAuthRedirectPath = (path) => {
//     return {
//         type: actionTypes.SET_AUTH_REDIRECT_PATH,
//         path: path
//     };
// };

// //this is set to run at page load to see if they have a token
// export const authCheckState = () => {
//     return dispatch => {
//         const token = localStorage.getItem('token');
//         const refresh_token = localStorage.getItem('refresh_token');

//         //if there is no token present
//         if (!token) {
//             // console.log('there was no token')
//             if(refresh_token){
//                 console.log('there was a refresh token though')
//                 //try to refresh the token
//                 dispatch(refreshToken());
//                 dispatch(setAuthRedirectPath('/admin'))
//             }else{
//                 //if neither are present make sure the state shows logged out
//                 dispatch(logout());
//             }
//         }

//         else {

//             //if there is a token present
//             const expirationDate = new Date(localStorage.getItem('expirationDate'));

//             //token is too old
//             if (expirationDate <= new Date()) {
//                 //todo send out a dispactch to request a new token with the refresh token
//                 if(refresh_token){
//                     //dispatch(refresh)

//                 }else{
//                     dispatch(logout());
//                 }
//             //the token is in the correct age, needs validated still.
//             } else {
//                 const userId = localStorage.getItem('userId');
//                 dispatch(setAuthRedirectPath('/'))
//                 dispatch(authSuccess(token, userId));
//                 dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
//             }
//         }
//         dispatch(fetchAdmin(token));
//     };
// };
